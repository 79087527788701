<template>
  <div class="team">
    <h3>Nasz zespół</h3>
    <p>
      Zdobyliśmy wykształcenie w mieście, ale wywodzimy się ze wsi i do dzisiaj bliskie jest nam rolnictwo i ogrodnictwo. Zawodowo zajmujemy się nowymi technologiami - optyką, automatyką, elektroniką. Na co dzień pracujemy przy konstruowaniu zaawansowanych urządzeń, które latają w kosmos, dbają o bezpieczeństwo, nadzorują linie produkcyjne. Postanowiliśmy wykorzystać naszą wiedzę, umiejętności i doświadczenie przy stworzeniu systemu do inteligentnego zarządzania uprawą.
    </p>
    <div class="table">
      <div class="cell">
        <div ref="elementToAnimate1" class="animated-element">
          <img src="./../assets/magdalena.jpg" />
          <div class="description">
            <div class="name">Magdalena Kowalewska</div>
            <p>Magister biologii o specjalności techniki laboratoryjne. Posiada
            doświadczenie jako laborant zdobyte podczas pracy jako biolog w
            laboratorium seminologicznym, gdzie odpowiedzialna była za
            samodzielne wykonywanie badań i analiz, preparacji materiału,
            kalibracji sprzętu laboratoryjnego oraz sporządzania raportów z
            wykonanych badań i analiz. Jest pasjonatką ogrodnictwa, aktualnie
            zdobywa doświadczenie w tym zakresie oraz tytuł na studiach
            podyplomowych na Uniwersytecie Przyrodniczym we Wrocławiu na
            kierunku „Integrowane i ekologiczne ogrodnictwo”.Funkcja w
            projekcie: główny opiekun instalacji badawczej / kierownik projektu</p>
          </div>
        </div>
      </div>
      <div class="cell">
        <div ref="elementToAnimate2" class="animated-element">
          <img src="./../assets/aleksander.jpg" />
          <div class="description">
            <div class="name">Aleksander Łubniewski</div>
            <p>Elektronik i programista specjalizujący się w systemach oświetlenia
            specjalistycznego, przetwarzania obrazów, elektroniki medycznej,
            sieci pomiarowych i automatyki budynkowej. Autor elektroniki systemu
            pomiarowego misji stratosferycznej pod patronatem Europejskiej
            Agencji Kosmicznej oraz bezzałogowych platform latających do
            mapowania terenu. Współautor opatentowanego rozwiązania IoT
            autodiagnostyki i zbierania danych. Autor publikacji z dziedziny
            automatyki, metrologii, czujników i elektroniki.Funkcja w projekcie:
            główny architekt sieci pomiarowej, główny elektronik-programista</p>
          </div>
        </div>
      </div>
      <div class="cell">
        <div ref="elementToAnimate3" class="animated-element">
          <img src="./../assets/jedrzej.jpg" />
          <div class="description">
            <div class="name">Jędrzej Kowalewski</div>
            <p>Absolwent Politechniki Wrocławskiej, realizator szeregu projektów
            badawczych. W latach 2013-2014 asystent naukowy w niemieckim
            Instytucie Fraunhofera IWS w Dreźnie – czołowej europejskiej
            placówce zajmującej się innowacyjnymi technologiami laserowymi i
            technikami skaningu optycznego. Założyciel i Prezes zarządu firmy
            Scanway, skupiającej się na technologiach satelitarnych dla
            przemysłu kosmicznego, jak i produkcyjnego. Ekspert ds. efektywnego
            transferu technologicznego z branży kosmicznej do przemysłu. W
            branży energetyki specjalista ds. optymalizacji wytwarzania energii
            odnawialnej z użyciem innowacyjnych rozwiązań. W swoich projektach
            współpracował m.in. z PGE oraz PGNiG.</p>
          </div>
        </div>
      </div>
      <div ref="elementToAnimate4" class="animated-element">
        <div class="cell">
          <img src="./../assets/tomek.jpg" />
          <div class="description">
            <div class="name">Tomek Olejnik</div>
            <p>Związany z komunikacją marketingową i mediami od 20 lat. Strateg i
            dyrektor kreatywny w kilku agencjach interaktywnych. Do jego
            specjalizacji należą komunikacja społeczna oraz psychologia
            marketingu. Prywatnie fan zdrowego stylu życia, racjonalnego
            odżywiania, naukowo zbadanej suplementacji oraz aktywności
            fizycznej. W ogrodzie ceni i relaks i pracę, a największą radość
            daje obserwowanie jej efektów. Jego misją jest zdobywać rozgłos dla
            produktów i usług, które zmieniają świat na lepsze. Dokładnie takich
            jak Leafmatic.Funkcja w projekcie: odpowiedzialny za marketing i
            public relations - osoba właściwa do kontaktu, jeśli chcesz o
            Leafmatic napisać/wspomnieć albo zaprosić do współpracy</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurTeam",
  mounted() {
    const options = {
      threshold: 0.5, // Kiedy uznajemy element za widoczny (50% widoczności)
    };

    // Utwórz obiekt Intersection Observer
    const observer = new IntersectionObserver(this.handleIntersection, options);

    // Obserwuj każdy niepowiązany element osobno
    this.observeElement("elementToAnimate1", observer);
    this.observeElement("elementToAnimate2", observer);
    this.observeElement("elementToAnimate3", observer);
    this.observeElement("elementToAnimate4", observer);
    // Dodaj więcej niepowiązanych elementów, jeśli jest taka potrzeba
  },
  methods: {
    observeElement(refName, observer) {
      const elementToAnimate = this.$refs[refName];
      observer.observe(elementToAnimate);
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element jest widoczny na ekranie, dodaj klasę animacji
          entry.target.classList.add("animate");
          // Zatrzymaj obserwację, aby nie uruchamiała się ponownie
          observer.unobserve(entry.target);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.team {
  padding: 1rem;
}
.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  gap: 5rem;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

h3 {
  text-align: center;
  margin-bottom: 3rem;
}

img {
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

p {
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 1200px;
  
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
}

.description {
  text-align: left;
}

.name {
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.cell {
  text-align: center;
}
</style>
