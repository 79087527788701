<template>
  <div class="interested-container">
    <h3>Interesuje Cię mierzenie parametrów Twoich upraw?</h3>
    <p>
      Chcesz dowiedzieć się, jak możesz wykorzystać nowoczesne technologie do
      inteligentnego zarządzania Twoją uprawą- ogrodem, szklarnią lub dowolną
      inną hodowlą roślin?
    </p>
    <div class="order" @click="scrollToSection('contact')">ZAMÓW</div>
  </div>
</template>

<script>
export default {
  name: "AreYouInterestedInMesurement",
  methods: {
    scrollToSection(section) {
      this.$globMet.googleEvent(section+"-clicked", "content-button", "Kliknięto w ZAMÓW", "1");
      this.$emit("scrollToSection", section);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/main.scss";
.interested-container {
  background-color: $main-colour;
  text-align: center;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

p {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.order {
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: $button-colour;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border-color: $button-colour;
  transition:
    background-color 0.2s,
    transform 0.2s;
  width: 9rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
.order a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
}

.order:hover {
  background-color: $button-colour-light;
  transform: translateY(-3px);
  cursor: pointer;
}
</style>
